import React from 'react';

import Text from 'components/Text';
import FullScreenWrapper from 'components/FullScreenWrapper';

const NotFoundPage = () => (
  <FullScreenWrapper>
    <Text type="h1">404: Not Found</Text>
    <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
  </FullScreenWrapper>
);

export default NotFoundPage;
